body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.App {
  display: wrap;
  align-items: center;
  justify-content: center;
  background: linear-gradient(125deg, rgba(36,0,24,1) 0%, rgba(72,81,205,1) 0%, rgba(255,0,125,1) 100%);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  color: #fff;
  border: 1px solid;
}

.developers-dropdown {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}
.date-form {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 30px;
}

.heading {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 60px;
  height: 70px;
  width: 500px;
}

.header {
  padding-top: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.logo {
  justify-content: center;
  display: flex;
  align-items: center;
}

.filters {
  display: grid;
  justify-content: space-around;
  align-items: center;
  padding-left: 320px;
  padding-right: 320px;
}

.date-heading {
  padding-bottom: 20px;
  margin: 0px;
  display: flex;
  font-size: 20px;
}
.date-input {
  align-items: center;
  justify-content: center;
  display: flex;
}
.enter-date {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 250px;
}

/* .react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 50%;
} */

.total-time {
  align-items: center;
  justify-content: center;
  display: flex;
}
.data-table {
  padding: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 3px solid white;
}
table th {
  justify-content: center;
  align-items: center;
  padding: 20px;
}
table td {
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: flex;
  width: 100%;
}

.download-section {
  align-items: center;
  justify-content: center;
  display: grid;
}
.dropdowns {
  align-items: center;
  justify-content: space-around;
  display: flex;
}

.reset-button{
  align-items: center;
  justify-content: center;
  display: grid;
}
.allboard
{
  display:contents;  
  justify-content: space-between;
  align-items: center;
}
.allboard .a
{
  display:flex;  
  justify-content: center;
  align-items: center;
}
.section-select{
  align-items: center;
  justify-content: center;
  display: wrap;  
} 
.t1{
  align-items: center;
  justify-content: space-between;
  display: flex;  
}
.t2{
  align-items: center;
  justify-content: space-between;
  display: flex;  
}
.t3{
  align-items: center;
  justify-content: space-between;
  display: flex;  
}
.t4{
  align-items: center;
  justify-content: space-between;
  display: flex;  
}
.t5{
  align-items: center;
  justify-content: space-between;
  display: flex;  
}
.allboardslink
{
  align-items: center;
  justify-content: center;
  display: flex;  
}
.addDeveloper{
  align-items: center;
  justify-content: center;
  display: flex; 
}
.addDevs{
  align-items: center;
  justify-content: space-between;
  display:contents; 
}
.head{
  align-items: center;
  display: flex;
  justify-content: center;
}


